import { LoginCallback } from '@thriveglobal/thrive-web-auth-core'
import {
    ErrorScreen,
    ErrorScreenVariant,
    FullScreenLoading
} from '@thriveglobal/thrive-web-leafkit'
import { Suspense } from 'react'
import NotConfiguredMarketingScreen from '../auth/NotConfiguredMarketingScreen'
import PermissionsRequestScreen from '../auth/PermissionsRequestScreen'
import TeamsAuthErrorScreen from '../auth/TeamsAuthErrorScreen'
import useMsTeamsAuth from '../hooks/useMsTeamsAuth/useMsTeamsAuth'
import { getOrGenerateUserId } from '../utils/getTempUserId'

const SPECIAL_ERROR_VARIANTS = ['AuthError']

const TeamsAuthProvider = () => {
    const tempUserId = getOrGenerateUserId()

    const {
        isLoggedIn,
        loading,
        error,
        reload,
        errorVariant,
        showPermissionRequestScreen,
        onTriggerTokenExchangeWithExtraPermissions
    } = useMsTeamsAuth(tempUserId)

    if (loading || reload) {
        return <FullScreenLoading />
    }

    if (SPECIAL_ERROR_VARIANTS.includes(errorVariant.variant)) {
        return <TeamsAuthErrorScreen errorMessage={tempUserId} />
    }

    if (errorVariant.variant === 'NotConfigured') {
        return <NotConfiguredMarketingScreen />
    }

    if (errorVariant.variant) {
        return <ErrorScreen variant={errorVariant.variant} />
    }

    if (error) {
        return <ErrorScreen variant={ErrorScreenVariant.General} />
    }

    if (showPermissionRequestScreen) {
        return (
            <PermissionsRequestScreen
                onClick={onTriggerTokenExchangeWithExtraPermissions}
                tempUserId={tempUserId}
            />
        )
    }

    if (isLoggedIn) {
        return (
            <Suspense fallback={<FullScreenLoading />}>
                <LoginCallback />
            </Suspense>
        )
    }

    return <></>
}

export default TeamsAuthProvider
