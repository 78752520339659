import { app } from '@microsoft/teams-js'
import {
    captureException,
    setTheme,
    store
} from '@thriveglobal/thrive-web-core'
import { useCallback, useEffect, useRef, useState } from 'react'
import { getThemeFromMsTeams } from '../../utils/getThemeFromMsTeams'
import {
    deleteRetryContextState,
    increaseRetryContextCount,
    loadRetryContextState
} from '../../utils/manageRetryStatesLocally'
import { retry } from '../../utils/promiseRetry'
import {
    tokenExchange,
    tokenExchangeWithTokenWithExtraPermissions
} from '../../utils/tokenExchange'

const onInitializeSuccess = () => {
    app.notifyAppLoaded()
    app.notifySuccess()

    return Promise.resolve(true)
}

export const useMsTeamsAuth = (tempUserId: string) => {
    const authTriggered = useRef(false)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [reload, setReload] = useState(false)
    const [errorVariant, setErrorVariant] = useState({
        variant: null, // needs to be set to type ErrorScreenVariant | null
        reason: ''
    })
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [context, setContext] = useState<app.Context>()
    const [showPermissionRequestScreen, setShowPermissionRequestScreen] =
        useState(false)

    const initializeTeamsApp = useCallback<() => Promise<boolean>>(() => {
        if (app.isInitialized()) {
            return onInitializeSuccess()
        }

        return retry<void, boolean>(
            app.initialize,
            onInitializeSuccess,
            (error, reload, retryCount) => {
                setReload(reload)
                captureException(error, {
                    message: `Failed to initialize Teams JS app, retry ${retryCount}`,
                    tempUserId
                })

                return true
            },
            loadRetryContextState,
            increaseRetryContextCount
        )
    }, [tempUserId])

    const setMsTeamsTheme = useCallback<(context: app.Context) => void>(
        (context) => {
            try {
                store.dispatch(setTheme(getThemeFromMsTeams(context.app.theme)))
            } catch (error) {
                captureException(error, {
                    message: 'Failed to set MS Teams Theme'
                })
                store.dispatch(setTheme(getThemeFromMsTeams('')))
            }
        },
        []
    )

    const getMsTeamsContext = useCallback<() => Promise<app.Context>>(() => {
        return retry<app.Context, app.Context>(
            app.getContext,
            (context) => {
                deleteRetryContextState()
                setContext(context)
                setMsTeamsTheme(context)

                return Promise.resolve(context)
            },
            (error, reload, retryCount) => {
                setReload(reload)
                captureException(error, {
                    message: `Failed to get Teams JS Context, retry ${retryCount}`,
                    tempUserId
                })

                return true
            },
            loadRetryContextState,
            increaseRetryContextCount
        )
    }, [setMsTeamsTheme, tempUserId])

    const triggerAuthFlow = useCallback(() => {
        setLoading(true)

        return initializeTeamsApp()
            .then(getMsTeamsContext)
            .then((context) =>
                tokenExchange(
                    setIsLoggedIn,
                    setErrorVariant,
                    setReload,
                    setShowPermissionRequestScreen,
                    context,
                    tempUserId
                )
            )
            .catch(() => {
                setError(true)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [initializeTeamsApp, getMsTeamsContext, tempUserId])

    const onTriggerTokenExchangeWithExtraPermissions = useCallback(() => {
        setShowPermissionRequestScreen(false)
        tokenExchangeWithTokenWithExtraPermissions(
            setIsLoggedIn,
            setErrorVariant,
            context,
            tempUserId
        )
    }, [context, tempUserId])

    useEffect(() => {
        if (!authTriggered.current) {
            authTriggered.current = true
            triggerAuthFlow()
        }
    }, [authTriggered, triggerAuthFlow])

    return {
        isLoggedIn,
        loading,
        reload,
        error,
        errorVariant,
        showPermissionRequestScreen,
        onTriggerTokenExchangeWithExtraPermissions
    }
}

export default useMsTeamsAuth
